import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import { BrowserRouter as Router, Route, Routes, HashRouter } from 'react-router-dom';
import WorkoutScreen from './components/screens/WorkoutScreen/WorkoutScreen';
import LandingPage from './components/screens/LandingPage/LandingPage';
import HomeScreen from './components/screens/HomeScreen/HomeScreen';
import CreateTemplateScreen from './components/screens/CreateTemplateScreen/CreateTemplateScreen';
import { buildExercises } from './resources/staticData';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GooseFitContextProvider } from './contexts/GooseFitContextProvider';


function App() {
  return (
    <div className="App">
      <QueryClientProvider client={new QueryClient()}>
        <GooseFitContextProvider>
          <GoogleOAuthProvider clientId="1006705275142-89e9i4jste8q2h9ru9e3ftctrip5t6e5.apps.googleusercontent.com">
            <Router>
              <Routes>
                <Route path="/workout" element={<WorkoutScreen />} />
                <Route path="/home" element={<HomeScreen />} />
                <Route path="/createTemplate" element={<CreateTemplateScreen />} />
                <Route path="/" element={<LandingPage />} />
              </Routes>
            </Router>
          </GoogleOAuthProvider>
        </GooseFitContextProvider>
      </QueryClientProvider>

    </div>
  );
}

export default App;
