import React, { useState, useEffect } from 'react';
import { getModalStyle } from '../../../../utils/utils';
import { Modal, Box, Stack, Typography, Button, Autocomplete, TextField, Card, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export default function AddExerciseModal({ visible, setVisible, exercises, onAddExercise }) {

    

    const [searchValue, setSearchValue] = useState('');
    const [filteredExercises, setFilteredExercises] = useState(exercises);
    useEffect(() => {
        setFilteredExercises(exercises);
    }, [exercises]);
    useEffect(() => {
        setFilteredExercises(exercises.filter(exercise => JSON.stringify(exercise).toLowerCase().includes(searchValue.toLowerCase())));
    }, [searchValue]);

    const onClose = () => {
        setVisible(false);
        setSearchValue(''); 
        setFilteredExercises(exercises); 
    }

    const onClickAdd = (id) => {
        setVisible(false);
        setSearchValue(''); 
        setFilteredExercises(exercises); 
        onAddExercise(id)
    }


    return (
        <Modal
            open={visible}
            onClose={onClose}
        >
            <Box sx={getModalStyle()}>
                <Stack spacing={2}>
                    <Typography variant='h5'>Add Exercise: </Typography>
                    <TextField
                        placeholder='Search'
                        value={searchValue}
                        onChange={(event) => {
                            setSearchValue(event.target.value);
                        }}
                    />
                    <div style={{overflow: 'scroll', maxHeight: `50vh`}}>
                        <Stack spacing={1}>
                            {filteredExercises.map((exercise, idx) => {
                                return (
                                    <Card key={idx}>
                                        <br/>
                                        <Grid container spacing={2}>
                                            <Grid xs={8}>
                                                <Typography>{exercise.name}</Typography>
                                            </Grid>
                                            <Grid xs={4}>
                                                <Button onClick={() => {onClickAdd(exercise.id)}}>
                                                    Add
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                )
                            })}
                        </Stack>
                    </div>
                </Stack>

            </Box>
        </Modal>
    )
}