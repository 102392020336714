import { Box, Button, CircularProgress, Container, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getModalStyle } from '../../../../utils/utils';
import { useQuery } from 'react-query';
import { createFriendRequest } from '../../../../api/api';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function AddFriendModal({ visible, setVisible }) {

    const [friendEmail, setFriendEmail] = useState('');
    const [sendSuccess, setSendSuccess] = useState(false);

    const onClose = () => {
        setVisible(false);
        setFriendEmail('');
        setSendSuccess(false);
    }

    const onReset = () => {
        setFriendEmail('');
        setSendSuccess(false);
    }

    const [sendQueryEnabled, setSendQueryEnabled] = useState(false);
    useQuery(["sendFriendRequest", { from: localStorage.getItem("userName"), to: friendEmail }], createFriendRequest, {
        enabled: sendQueryEnabled,
        onSuccess: res => {
            console.log(res);
            setSendQueryEnabled(false);
            setSendSuccess(true);
        },
        onError: err => {
            console.log(err);
            setSendQueryEnabled(false);
        },
        retry: 3
    })

    return (
        <Modal
            open={visible}
            onClose={onClose}
        >
            <Box sx={{ ...getModalStyle(), p: 3, width: '80%' }}>
                <Stack spacing={3}>
                    <Typography variant="h5">Send Friend Request</Typography>
                    <Typography> Enter a friend's email address to connect. Friends can see your profile, including templates and workouts</Typography>

                </Stack>
                <br />
                {(!sendQueryEnabled && !sendSuccess) && <Stack spacing={3}>
                    <TextField value={friendEmail} placeholder='Email Address' onChange={(event) => setFriendEmail(event.target.value)}></TextField>
                    <Button onClick={() => setSendQueryEnabled(true)}>Send</Button>
                    <Button color='error' onClick={onClose}>Cancel</Button>
                </Stack>}
                {sendQueryEnabled && <CircularProgress />}
                {sendSuccess && <Stack>
                    <CheckCircleIcon sx={{ color: 'green' }} fontSize='large' />
                    <Button onClick={() => onReset()}>Send Another</Button>
                </Stack>}
            </Box>
        </Modal>
    )

}