import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getModalStyle } from '../../../../utils/utils';

export default function TemplatePreviewModal({ visible, setVisible, template, exercises }) {

    const onConfirmStartWorkout = () => {
        localStorage.setItem("currentWorkoutExerciseList", JSON.stringify(exercises));
        localStorage.setItem("currentWorkoutTemplate", JSON.stringify(template));
        window.location.replace('/workout');
    }

    const onClose = () => {
        setVisible(false);
    }

    return (
        <Modal
            open={visible}
            onClose={onClose}
        >
            <Box sx={getModalStyle()}>
                <Stack spacing={3}>
                    <Typography variant="h4">{template.name}</Typography>
                    <div style={{ overflow: 'scroll', maxHeight: `${Math.round(window.screen.height * .5)}px` }}>
                        <Stack spacing={2}>
                            {template.exercises.map((ex, idx) => {
                                const exercise = exercises.filter(exer => exer.id == ex.id)[0];
                                if (exercise == undefined) {
                                    return <div key={idx}>Error: Exercise not Found</div>
                                }
                                return <Typography variant="h6" key={idx}>{idx + 1}: {exercise.name} - {ex.numSets}x{ex.repGoal}</Typography>
                            })}

                        </Stack>
                    </div>
                    <Button onClick={onConfirmStartWorkout}>Start Workout </Button>
                </Stack>
            </Box>
        </Modal>
    )
}


