import { createTheme } from "@mui/material";


export const getModalStyle = () => {
    return {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
        maxHeight: '75%', 
        borderRadius: '15px', 
        justifyContent: 'center'
    }
}

export const getTitleTheme = () => {
    return createTheme({
        typography: {
          fontFamily: [
            'DM Serif Display',
          ].join(','),
        },
      });
}