import axios from "axios";

const url = "https://7exod5wxob.execute-api.us-west-2.amazonaws.com/prod/"; 

const api = axios.create({
    baseURL: url
})

export async function makeAxiosPostCall(resource, data) {
    const fullUrl = url + resource; 
    return api.post(fullUrl, data); 
}

export const testApi = async ({ queryKey }) => {
    const [_key] = queryKey
    return makeAxiosPostCall("test", null); 
}

export const listTemplates = async ({ queryKey }) => {
    const [_key, createdBy] = queryKey
    return makeAxiosPostCall("listTemplates", {
        createdBy
    }); 
}

export const listExercises = async ({ queryKey }) => {
    const [_key] = queryKey
    return makeAxiosPostCall("listExercises", null); 
}

export const createTemplate = async ({ queryKey }) => {
    const [_key, template] = queryKey
    return makeAxiosPostCall("createTemplate", {
        template
    }); 
}

export const saveWorkout = async ({ queryKey }) => {
    const [_key, workout] = queryKey
    return makeAxiosPostCall("saveWorkout", {
        workout
    }); 
}

export const listWorkouts = async ({ queryKey }) => {
    const [_key, createdBy] = queryKey
    return makeAxiosPostCall("listWorkouts", {
        createdBy
    }); 
}

export const createFriendRequest = async ({ queryKey }) => {
    const [_key, friendRequest] = queryKey
    return makeAxiosPostCall("createFriendRequest", {
        friendRequest
    }); 
}

export const listFriendRequests = async ({ queryKey }) => {
    const [_key, to] = queryKey
    return makeAxiosPostCall("listFriendRequests", {
        to
    }); 
}

export const answerFriendRequest = async ({ queryKey }) => {
    const [_key, friendRequest, accepted] = queryKey
    return makeAxiosPostCall("answerFriendRequest", {
        friendRequest, 
        accepted
    }); 
}

export const listFriends = async ({ queryKey }) => {
    const [_key, user] = queryKey
    return makeAxiosPostCall("listFriends", {
        user
    }); 
}

export const login = async ({ queryKey }) => {
    const [_key, user] = queryKey
    return makeAxiosPostCall("login", {
        user
    }); 
}

