export const staticTemplates = [
    {
        id: '123456', 
        name: 'Day 1: Push',
        createdBy: 'Jack', 
        exercises: [
            {
                id: '1', 
                numSets: 3, 
                repGoal: 8
            },
            {
                id: '2', 
                numSets: 3, 
                repGoal: 8
            }
        ],
    }
]

export const staticExercises = [
    {
        id: '1', 
        name: 'Bench Press', 
        videoId: 'rT7DgCr-3pg'
    },
    {
        id: '2', 
        name: 'Pull Up', 
        videoId: 'rT7DgCr-3pg'
    },
    {
        id: '3', 
        name: 'Lateral Raise', 
        videoId: 'rT7DgCr-3pg'
    },
    {
        id: '4', 
        name: 'Bench Press', 
        videoId: 'rT7DgCr-3pg'
    },
]

export const buildExercises = [
    {
        "name": "Bent Over Row (Barbell)",
        "videoId": "RQU8wZPbioA",
        "id": "temp"
    },
    {
        "name": "Pull Up",
        "videoId": "IpxAG2z91Ys",
        "id": "temp"
    },
    {
        "name": "Bent Over One Arm Row (Dumbbell)",
        "videoId": "dFzUjzfih7k",
        "id": "temp"
    },
    {
        "name": "Lat Pulldown (Cable)",
        "videoId": "7JnP8dFbS14",
        "id": "temp"
    },
    {
        "name": "Bicep Curl (Dumbbell)",
        "videoId": "ykJmrZ5v0Oo",
        "id": "temp"
    },
    {
        "name": "Running",
        "videoId": "_kGESn8ArrU",
        "id": "temp"
    },
    {
        "name": "Squat (Barbell)",
        "videoId": "3PRwtVpyslo",
        "id": "temp"
    },
    {
        "name": "Romanian Deadlift (Barbell)",
        "videoId": "ysMo_VFN-dY",
        "id": "temp"
    },
    {
        "name": "Leg Extension (Machine)",
        "videoId": "4ZDm5EbiFI8",
        "id": "temp"
    },
    {
        "name": "Seated Leg Curl (Machine)",
        "videoId": "Orxowest56U",
        "id": "temp"
    },
    {
        "name": "Bench Press (Barbell)",
        "videoId": "rT7DgCr-3pg",
        "id": "temp"
    },
    {
        "name": "Incline Bench Press (Dumbbell)",
        "videoId": "8iPEnn-ltC8",
        "id": "temp"
    },
    {
        "name": "Triceps Extension (Dumbbell)",
        "videoId": "-Vyt2QdsR7E",
        "id": "temp"
    },
    {
        "name": "Triceps Pushdown (Cable - Straight Bar)",
        "videoId": "6Fzep104f0s",
        "id": "temp"
    },
    {
        "name": "Incline Row (Dumbbell)",
        "videoId": "tZUYS7X50so",
        "id": "temp"
    },
    {
        "name": "Bicep Curl (Barbell)",
        "videoId": "N5x5M1x1Gd0",
        "id": "temp"
    },
    {
        "name": "Seated Palms Up Wrist Curl (Dumbbell)",
        "videoId": "7ac_qmBjkFI",
        "id": "temp"
    },
    {
        "name": "Bulgarian Split Squat",
        "videoId": "2C-uNgKwPLE",
        "id": "temp"
    },
    {
        "name": "Seated Calf Raise (Machine)",
        "videoId": "JbyjNymZOt0",
        "id": "temp"
    },
    {
        "name": "Overhead Press (Barbell)",
        "videoId": "G2qpTG1Eh40",
        "id": "temp"
    },
    {
        "name": "Incline Chest Fly (Dumbbell)",
        "videoId": "8oR5hBwbIBc",
        "id": "temp"
    },
    {
        "name": "Triceps Extension (Cable)",
        "videoId": "JDEDaZTEzGE",
        "id": "temp"
    },
    {
        "name": "Push Up",
        "videoId": "IODxDxX7oi4",
        "id": "temp"
    },
    {
        "name": "Leg Press",
        "videoId": "M_xoJ0iRLFc",
        "id": "temp"
    },
    {
        "name": "Lateral Raise (Cable)",
        "videoId": "Z5FA9aq3L6A",
        "id": "temp"
    },
    {
        "name": "Face Pull (Cable)",
        "videoId": "0Po47vvj9g4",
        "id": "temp"
    },
    {
        "name": "Dumbbell Step-Up",
        "videoId": "9ZknEYboBOQ",
        "id": "temp"
    },
    {
        "name": "Lying Leg Curl (Machine)",
        "videoId": "n5WDXD_mpVY",
        "id": "temp"
    },
    {
        "name": "Db Bicep Curl",
        "videoId": "ykJmrZ5v0Oo",
        "id": "temp"
    },
    {
        "name": "Skullcrusher (Dumbbell)",
        "videoId": "ir5PsbniVSc",
        "id": "temp"
    },
    {
        "name": "Cable Crossover",
        "videoId": "taI4XduLpTk",
        "id": "temp"
    },
    {
        "name": "Lat Pulldown (Close Grip)",
        "videoId": "IjoFCmLX7z0",
        "id": "temp"
    },
    {
        "name": "Seated Calf Raise (Plate Loaded)",
        "videoId": "JbyjNymZOt0",
        "id": "temp"
    },
    {
        "name": "1 Leg Press",
        "videoId": "xT5-HS6e9O4",
        "id": "temp"
    },
    {
        "name": "French Press",
        "videoId": "LM8ahUmGnOQ",
        "id": "temp"
    },
    {
        "name": "Seated Leg Press (Machine)",
        "videoId": "IZxyjW7MPJQ",
        "id": "temp"
    },
    {
        "name": "Bench Press (Dumbbell)",
        "videoId": "VmB1G1K7v94",
        "id": "temp"
    },
    {
        "name": "Seated Row (Cable)",
        "videoId": "UCXxvVItLoM",
        "id": "temp"
    },
    {
        "name": "Romanian Deadlift (Dumbbell)",
        "videoId": "hQgFixeXdZo",
        "id": "temp"
    },
    {
        "name": "Standing Calf Raise (Dumbbell)",
        "videoId": "ADIDoYt_ko4",
        "id": "temp"
    },
    {
        "name": "Seated Overhead Press (Dumbbell)",
        "videoId": "b5JzUH8gsOg",
        "id": "temp"
    },
    {
        "name": "Hammer Curl (Dumbbell)",
        "videoId": "TwD-YGVP4Bk",
        "id": "temp"
    },
    {
        "name": "Incline Curl (Dumbbell)",
        "videoId": "soxrZlIl35U",
        "id": "temp"
    },
    {
        "name": "Cable Crunch",
        "videoId": "6GMKPQVERzw",
        "id": "temp"
    },
    {
        "name": "Seated Cable Fly",
        "videoId": "CT7vDnPJZ9w",
        "id": "temp"
    },
    {
        "name": "Decline Crunch",
        "videoId": "QhGU5cmNZds",
        "id": "temp"
    },
    {
        "name": "Incline Bench Press (Smith Machine)",
        "videoId": "8urE8Z8AMQ4",
        "id": "temp"
    },
    {
        "name": "Deadlift (Barbell)",
        "videoId": "WP0IFHkkRZ0",
        "id": "temp"
    },
    {
        "name": "Cable French Press",
        "videoId": "qLltmkcWbIA",
        "id": "temp"
    },
    {
        "name": "Heel Elevated Squat",
        "videoId": "aibuOMXMnp0",
        "id": "temp"
    },
    {
        "name": "Tricep Rope Pushdown",
        "videoId": "-xa-6cQaZKY",
        "id": "temp"
    },
    {
        "name": "Chest Press (Machine)",
        "videoId": "NwzUje3z0qY",
        "id": "temp"
    },
    {
        "name": "Preacher Curl (Dumbbell)",
        "videoId": "fuK3nFvwgXk",
        "id": "temp"
    },
    {
        "name": "Lateral Raise (Machine)",
        "videoId": "0FUpcwj_1z4",
        "id": "temp"
    },
    {
        "name": "Crunch (Machine)",
        "videoId": "_O1xunCfYEM",
        "id": "temp"
    },
    {
        "name": "Seated Cable Face Pull",
        "videoId": "QbpmaP-Oxz8",
        "id": "temp"
    },
    {
        "name": "Romanian Deadlift (Smith Machine)",
        "videoId": "NBR6tozmx2I",
        "id": "temp"
    }
]
  

