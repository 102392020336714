import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getModalStyle } from '../../../../utils/utils';

export default function WorkoutViewModal({ visible, setVisible, workout, exercises, templates }) {

    const onClose = () => {
        setVisible(false);
    }

    const [templateName, setTemplateName] = useState(''); 
    useEffect(() => {
        const template = templates.filter(temp => temp.id == workout.templateId)[0]; 
        if (template == undefined) {
            setTemplateName(''); 
        } else {
            setTemplateName(template.name);
        }
    }, [workout, templates, exercises]); 


    return (
        <Modal
            open={visible}
            onClose={onClose}
        >
            <Box sx={getModalStyle()}>
                <Stack spacing={3}>
                    <Typography variant="h5">{templateName} - {new Date(parseInt(workout.timestamp)).toLocaleDateString()}</Typography>
                    <div style={{ overflow: 'scroll', maxHeight: `${Math.round(window.screen.height * .5)}px` }}>
                        <Stack spacing={2}>
                            {workout.exercises.map((ex, idx) => {
                                const exercise = exercises.filter(exer => exer.id == ex[0].exerciseId)[0];
                                if (exercise == undefined) {
                                    return <div key={idx}>Error: Exercise not Found</div>
                                }
                                return (
                                    <div>
                                        <Typography variant="h6" key={idx}> {exercise.name}</Typography>
                                        {ex.map((set, setIdx) => {
                                            return <Typography variant="h6" key={`${idx}-${setIdx}`}>Set {setIdx + 1}: {set.weight} lbs x {set.reps} reps</Typography>
                                        })}
                                    </div>
                                )
                            })}

                        </Stack>
                    </div>
                </Stack>
            </Box>
        </Modal>
    )
}