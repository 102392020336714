import React, { useState } from 'react';
import GoosefitHeader from '../../other/GoosefitHeader';
import { Box, Button, CircularProgress, Container, IconButton, Paper, Stack, TextField, ThemeProvider, Typography } from '@mui/material';
import logo from '../../../resources/logo.jpg';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useGoosefitGoogleLogin } from '../../../hooks/useGoosefitGoogleLogin';
import { getTitleTheme } from '../../../utils/utils';

export default function HomeScreen({ }) {

    if (checkIsLoggedIn()) {
        window.location.replace('/home');
    }


    const {
        onGoogleSignInError, 
        onGoogleSignInSuccess, 
        loginQueryEnabled
    } = useGoosefitGoogleLogin(); 

    return (
        <Container>
            <Stack spacing={2} sx={{ alignItems: 'center' }}>
                <br />
                <ThemeProvider theme={getTitleTheme()}>
                    <Typography variant="h2">GooseFit</Typography>
                </ThemeProvider>
                
                <Box>
                    <img src={logo} width={"75%"} />
                </Box>

                <br />

                {loginQueryEnabled ? <CircularProgress /> : 
                <GoogleLogin
                    onSuccess={onGoogleSignInSuccess}
                    onError={onGoogleSignInError}
                    width={window.screen.width * .5}
                    shape='pill'
                    text="signin_with"
                    size='large'
                    theme='outline'
                />}
                
                <br/>
            </Stack>
        </Container>
    )
}

export const checkIsLoggedIn = (): boolean => {
    const name = localStorage.getItem("userName");

    if (name == undefined) {
        return false;
    } else {
        return true;
    }
}