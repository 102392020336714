import React, {useState, useContext, createContext} from 'react';
import { useQuery } from 'react-query';
import { answerFriendRequest, listFriendRequests } from '../api/api';
import { FriendRequest } from '../types/types';
import { useFriendsContext } from './FriendsContext';

const FriendRequestsContext = createContext(null); 

export function useFriendRequestsContext() {
    return useContext(FriendRequestsContext); 
}

export default function FriendRequestsContextProvider(props) {

    const  {
        reloadFriends
    } = useFriendsContext(); 

    const [requests, setRequests] = useState<FriendRequest[]>([]); 
    const [requestsIsLoading, setRequestsIsLoading] = useState(true); 

    useQuery(["listFriendRequests", localStorage.getItem("userName")], listFriendRequests, {
        enabled: requestsIsLoading, 
        onSuccess: response => {
            setRequestsIsLoading(false); 
            setRequests(response.data.friendRequests)
        }, 
        onError: err => {
            console.log(err); 
            setRequestsIsLoading(false); 
        }, 
        retry: 3 
    })

    const [responseIdx, setResponseIdx] = useState(-1); 
    const [responseAccept, setResponseAccept] = useState(false); 
    const [responseEnabled, setResponseEnabled] = useState(false); 
    useQuery(["answerFriendRequest", requests[responseIdx], responseAccept], answerFriendRequest, {
        enabled: responseEnabled, 
        onSuccess: response => {
            if (responseAccept) {
                reloadFriends(); 
            }
            setResponseEnabled(false); 
            setResponseIdx(-1); 
            setRequestsIsLoading(true); 
        }, 
        onError: err => {
            setResponseEnabled(false); 
            setResponseIdx(-1); 
        }, 
        retry: 3
    })
    const respondToRequest = (idx, accept) => {
        setResponseIdx(idx); 
        setResponseAccept(accept); 
        setResponseEnabled(true); 
    }
    const returnValue = {
        requests, 
        requestsIsLoading, 
        respondToRequest, 
        responseEnabled, 
        responseIdx
    }

    return (
        <>
            <FriendRequestsContext.Provider value={returnValue}>
                {props.children}
            </FriendRequestsContext.Provider>
        </>
    )
}