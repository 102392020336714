import React, {useState} from 'react';
import Card from '@mui/material/Card';
import { Box, Checkbox, Container, TextField, Stack, Typography, InputAdornment, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import VideoModal from '../../../other/VideoModal';

export default function WorkoutExercise({exercise, numSets, repGoal, exerciseIdx, workoutState, workoutHistory}) {

    const [videoModalVisible, setVideoModalVisible] = useState(false); 

    return (
        <Card variant='outlined'> 
            <Stack direction="row">
                <Typography variant="h5" component="h2">
                    {exercise.name}
                </Typography>
                <IconButton
                    onClick={() => {
                        setVideoModalVisible(true); 
                    }}
                >
                    <OndemandVideoIcon/>
                </IconButton>
            </Stack>
            
            <Stack spacing={2}>
                {workoutState.state[exerciseIdx].map((set, idx) => {
                    return (
                        <Stack direction="row" spacing={1} key={idx}>
                            <div style={{width: "20%"}}>Set {idx + 1}</div>
                            <TextField 
                                label="Weight"
                                size="small"
                                fullWidth={false}
                                value={set.weight || ''}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                                }}
                                onChange={(event) => {workoutState.changeWeight(exerciseIdx, idx, new Number(event.target.value))}}
                            />
                            <TextField 
                                label="Reps"
                                size="small"
                                fullWidth={false}
                                value={set.reps || ''}
                                type="number"
                                onChange={(event) => {workoutState.changeReps(exerciseIdx, idx, new Number(event.target.value))}}
                            />
                            <Checkbox 
                                checked={set.isCompleted} 
                                onChange={(event) => {workoutState.changeCompleted(exerciseIdx, idx, event.target.checked)}}
                            />
                        </Stack>
                    )
                })}
            </Stack>
            <VideoModal visible={videoModalVisible} setVisible={setVideoModalVisible} exercise={exercise} workoutHistory={workoutHistory}/>
        </Card>
    ); 
}