import React, { useState } from 'react';
import GoosefitHeader from '../../other/GoosefitHeader';
import { Box, Button, Card, Container, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import AddExerciseModal from './subcomponents/AddExerciseModal';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery } from 'react-query';
import { createTemplate } from '../../../api/api';
import ConfirmCreateTemplateModal from './subcomponents/ConfirmCreateTemplateModal';
import { useExercisesContext } from '../../../contexts/ExercisesContext';


export default function CreateTemplateScreen({ }) {

    const {
        exercises,
        exercisesIsLoading
    } = useExercisesContext();

    const [templateName, setTemplateName] = useState('');

    const [exercisesInTemplate, setExercisesInTemplate] = useState([]);
    const [addExerciseModalVisible, setAddExerciseModalVisible] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false); 

    const onAddExercise = (id) => {
        const newExercises = exercisesInTemplate;
        newExercises.push({
            id,
            sets: 3,
            reps: 10
        })
        setExercisesInTemplate(exercisesInTemplate)
    }

    const onRemoveExercise = (idx) => {
        const newExercises = [];
        exercisesInTemplate.forEach((exerciseInTemplate, i) => {
            if (i !== idx) {
                newExercises.push(exerciseInTemplate);
            }
        })
        setExercisesInTemplate(newExercises);
    }

    const onChangeRepsOrSets = (isSets, idx, newVal) => {
        const newExercises = [];
        exercisesInTemplate.forEach((exerciseInTemplate, i) => {
            if (i !== idx) {
                newExercises.push(exerciseInTemplate);
            } else {
                newExercises.push({
                    ...exerciseInTemplate,
                    reps: isSets ? exerciseInTemplate.reps : new Number(newVal),
                    sets: !isSets ? exerciseInTemplate.sets : new Number(newVal)
                })
            }
        })
        setExercisesInTemplate(newExercises);
    }

    const getWorkoutTemplate = () => {
        const template = {
            id: "temp",
            name: templateName,
            createdBy: localStorage.getItem("userName"), 
            exercises: exercisesInTemplate.map(exer => {
                return {
                    id: exer.id, 
                    numSets: exer.sets, 
                    repGoal: exer.reps
                }
            })
        }
        return template; 
    }

    

    return (
        <div style={{ width: "100%" }}>
            <GoosefitHeader />
            <br />
            <Container>
                <Paper elevation={3}>
                    <Stack spacing={3}>
                        <br />
                        <Typography variant='h4'>New Template</Typography>

                        <Container maxWidth='xl'>
                            <TextField placeholder='Template Name' value={templateName} onChange={({ target }) => setTemplateName(target.value)} />
                        </Container>

                        {exercisesInTemplate.map((exerciseInTemplate, idx) => {
                            const exercise = exercises.filter((exercise) => exerciseInTemplate.id == exercise.id)[0]
                            if (exercise == undefined) {
                                return <div key={idx}>Error: Could not find exercise</div>
                            }
                            return (
                                <Grid container spacing={1} key={idx}>
                                    <Grid xs={6}>
                                        <Typography variant='h6'>{exercise.name}</Typography>
                                    </Grid>
                                    <Grid xs={2.3}>
                                        <TextField
                                            label="Sets"
                                            size="small"
                                            fullWidth={false}
                                            type="number"
                                            value={exerciseInTemplate.sets}
                                            onChange={({ target }) => onChangeRepsOrSets(true, idx, target.value)}
                                        />
                                    </Grid>
                                    <Grid xs={2.3}>
                                        <TextField
                                            label="Reps"
                                            size="small"
                                            fullWidth={false}
                                            type="number"
                                            value={exerciseInTemplate.reps}
                                            onChange={({ target }) => onChangeRepsOrSets(false, idx, target.value)}
                                        />
                                    </Grid>
                                    <Grid xs={1}>
                                        <IconButton onClick={() => onRemoveExercise(idx)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>


                                </Grid>
                            )
                        })}
                        <Button onClick={() => setAddExerciseModalVisible(true)}>Add Exercise</Button>
                        <Button onClick={() => setConfirmModalVisible(true)} disabled={exercisesInTemplate.length == 0 || templateName.length == 0}>Save Template</Button>
                    </Stack>
                </Paper>
            </Container>
            <AddExerciseModal visible={addExerciseModalVisible} setVisible={setAddExerciseModalVisible} exercises={exercises} onAddExercise={onAddExercise} />
            <ConfirmCreateTemplateModal visible={confirmModalVisible} setVisible={setConfirmModalVisible} getWorkoutTemplate={getWorkoutTemplate}/>
        </div>
    )
}