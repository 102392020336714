import React, { useState } from 'react';
import { jwtDecode } from 'jwt-decode'; 
import { useQuery } from 'react-query';
import { login } from '../api/api';
import { User } from '../types/types';

export const useGoosefitGoogleLogin = () => {
    const onGoogleSignInSuccess = (response) => {
        const decoded: any = jwtDecode(response.credential); 
        console.log('decoded: ', decoded); 
        setUser({
            userName: decoded.email, 
            name: decoded.name, 
            picture: decoded.picture
        })
        setLoginQueryEnabled(true); 
    }

    const onGoogleSignInError = () => {
        console.log('google err');
    }

    const [user, setUser] = useState<User>(undefined); 
    const [loginQueryEnabled, setLoginQueryEnabled] = useState(false); 
    useQuery(["loginQuery", user], login, {
        enabled: loginQueryEnabled, 
        onSuccess: response => {
            setLoginQueryEnabled(false); 
            localStorage.setItem("userName", user.userName);
            window.location.replace('/home');
        }, 
        onError: err => {
            console.log(err); 
        }, 
        retry: 3
    })

    return {
        onGoogleSignInError, 
        onGoogleSignInSuccess, 
        loginQueryEnabled
    }
}