import React, {useState, useContext, createContext} from 'react';
import { useQuery } from 'react-query';
import { listExercises } from '../api/api';

const ExercisesContext = createContext(null); 

export function useExercisesContext() {
    return useContext(ExercisesContext); 
}

export default function ExercisesContextProvider(props) {

    const [exercises, setExercises] = useState([]); 
    const [exercisesIsLoading, setExercisesIsLoading] = useState(true); 

    useQuery(["listExercises"], listExercises, {
        enabled: exercisesIsLoading, 
        onSuccess: response => {
            setExercisesIsLoading(false); 
            setExercises(response.data.exercises)
        }, 
        onError: err => {
            console.log(err); 
            setExercisesIsLoading(false); 
        }, 
        retry: 3 
    })

    const returnValue =  {
        exercises, 
        exercisesIsLoading
    }

    return (
        <>
            <ExercisesContext.Provider value={returnValue}>
                {props.children}
            </ExercisesContext.Provider>
        </>
    )
}