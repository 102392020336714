import React, {useState, useContext, createContext} from 'react';
import { useQuery } from 'react-query';
import { listWorkouts } from '../api/api';
import { Workout } from '../types/types';

const WorkoutContext = createContext(null); 

export function useWorkoutContext() {
    return useContext(WorkoutContext); 
}

export default function WorkoutContextProvider(props) {

    const [workouts, setWorkouts] = useState<Workout[]>([]); 
    const [workoutsIsLoading, setWorkoutsIsLoading] = useState(true); 

    useQuery(["listWorkouts", localStorage.getItem("userName")], listWorkouts, {
        enabled: workoutsIsLoading, 
        onSuccess: response => {
            setWorkoutsIsLoading(false); 
            setWorkouts(response.data.workouts)
        }, 
        onError: err => {
            console.log(err); 
            setWorkoutsIsLoading(false); 
        }, 
        retry: 3 
    })

    const returnValue = {
        workouts, 
        workoutsIsLoading
    }

    return (
        <>
            <WorkoutContext.Provider value={returnValue}>
                {props.children}
            </WorkoutContext.Provider>
        </>
    )
}