import React from 'react';
import ExercisesContextProvider from './ExercisesContext';
import TemplatesContextProvider from './TemplatesContext';
import WorkoutContextProvider from './WorkoutContext';
import FriendsContextProvider from './FriendsContext';
import FriendRequestsContextProvider from './FriendRequestsContext';

export const GooseFitContextProvider = ({ children }) => (
    <ExercisesContextProvider>
        <TemplatesContextProvider>
            <WorkoutContextProvider>
                <FriendsContextProvider>
                    <FriendRequestsContextProvider>
                        {children}
                    </FriendRequestsContextProvider>
                </FriendsContextProvider>
            </WorkoutContextProvider>
        </TemplatesContextProvider>
    </ExercisesContextProvider>
)