import { Box, Button, Container, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTimer } from 'react-timer-hook';
import TimerIcon from '@mui/icons-material/Timer';
import { getModalStyle } from '../../../utils/utils';

export default function RestTimer({ }) {

    const [modalOpen, setModalOpen] = useState(false); 

    const getFullNumber = (num) => {
        return num > 9 ? `${num}` : `0${num}`
    }

    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp: new Date(), onExpire: () => { } });

    const setTimer = (mins, secs) => {
        const newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + secs);
        newDate.setMinutes(newDate.getMinutes() + mins)
        restart(newDate);
    }



    return (<Box sx={{backgroundColor: 'white', color: 'black', borderRadius: '25%'}}>
        {isRunning ?
            <Typography sx={{padding: '.5em'}}>{getFullNumber(minutes)}:{getFullNumber(seconds)}</Typography> :
            <IconButton onClick={()=>setModalOpen(true)}> <TimerIcon /></IconButton>
        }
        <Modal
            open={modalOpen}
            onClose={() => {setModalOpen(false)}}
        >
            {!isRunning ? <Container sx={getModalStyle()}>
                <Typography>Start Rest Timer</Typography>
                <Stack>
                    <Button onClick={() => {setTimer(1, 0); setModalOpen(false)}}> 1:00</Button>
                    <Button onClick={() => {setTimer(2, 0); setModalOpen(false)}}> 2:00</Button>
                    <Button onClick={() => {setTimer(3, 0); setModalOpen(false)}}> 3:00</Button>
                </Stack>
            </Container> 
            : 
            <Container sx={getModalStyle()}>
                <Typography>Rest Timer</Typography>
            </Container>
            }

        </Modal>
    </Box>)

}