import React, { useState } from 'react';
import { getModalStyle } from '../../../../utils/utils';
import { Modal, Box, Stack, Typography, Button, CircularProgress, Container } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { createTemplate } from '../../../../api/api';
import { useQuery } from 'react-query';

export default function ConfirmCreateTemplateModal({visible, setVisible, getWorkoutTemplate}) {

    const onClose = () => {
        setVisible(false); 
    }

    const [createTemplateQueryEnabled, setCreateTemplateQueryEnabled] = useState(false); 

    useQuery(["createTemplateQuery", getWorkoutTemplate()], createTemplate, {
        enabled: createTemplateQueryEnabled,
        onSuccess: response => {
            setCreateTemplateQueryEnabled(false); 
            window.location.replace('/home'); 
        },
        onError: err => {
            console.log(err);
            setCreateTemplateQueryEnabled(false); 
            window.location.replace('/home'); 
        },
        retry: 3
    })

    
    return (
        <Modal
            open={visible}
            onClose={onClose}
        >
            <Box sx={getModalStyle()}>
                {
                    createTemplateQueryEnabled ? <Container><CircularProgress /></Container>  : 
                    <Stack spacing={2}>
                        <Typography variant="h6">Are you sure you want to create this template?</Typography>
                        <Button onClick={onClose} color='error' variant='outlined'>Nevermind, I'm not done</Button>
                        <Button variant='outlined' onClick={() => {setCreateTemplateQueryEnabled(true)}}>Yes, Save Template</Button>
                    </Stack>
                }
            </Box>
        </Modal>
    )
}