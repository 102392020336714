import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Menu, MenuItem } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import '../../../App.css';
import logo from '../../../resources/logo.jpg'
import LogoutIcon from '@mui/icons-material/Logout';
import { getTitleTheme } from '../../../utils/utils';

export default function GoosefitHeader({ }) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.clear(); 
        window.location.replace('/'); 
    }

    return (
        <AppBar position="sticky">
            <Toolbar>
                <img src={logo} width={'12%'} style={{borderRadius: '25%'}}/>
                <ThemeProvider theme={getTitleTheme()}>
                    <Typography variant="h3" sx={{ flexGrow: 1 }} onClick={() => {window.location.replace('/home')}}> Goosefit </Typography>
                </ThemeProvider>

                <Box sx={{backgroundColor: 'white', color: 'black', borderRadius: '25%'}}>
                    <IconButton onClick={handleLogout}> <LogoutIcon /></IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    )
}