import React, { useState } from 'react';
import GoosefitHeader from '../../other/GoosefitHeader';
import { AppBar, Box, Button, CircularProgress, Container, IconButton, LinearProgress, Stack, Toolbar, Typography, createTheme } from '@mui/material';
import WorkoutExercise from './subcomponents/WorkoutExercise';
import CancelWorkoutModal from './subcomponents/CancelWorkoutModal';
import { useWorkoutState } from './hooks/useWorkoutState';
import { useExercisesContext } from '../../../contexts/ExercisesContext';
import { useWorkoutContext } from '../../../contexts/WorkoutContext';
import RestTimer from '../../other/Timer/RestTimer';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider } from '@emotion/react';
import { getTitleTheme } from '../../../utils/utils';

export default function WorkoutScreen({}) {

    const [cancelWorkoutModalVisible, setCancelWorkoutModalVisible] = useState(false); 

    const getActiveTemplate = () => {
        const temp = JSON.parse(localStorage.getItem("currentWorkoutTemplate"));
        if (temp == undefined) {
            window.location.replace('/home');
        }
        return temp;
    }

    const template = getActiveTemplate(); 
    const {
        exercises,
        exercisesIsLoading
    } = useExercisesContext(); 

    const workoutState = useWorkoutState(template); 

    const {
        workouts, 
        workoutsIsLoading
    } = useWorkoutContext(); 

    return (
        <div style={{ width: "100%" }}>            
            <div>
                <AppBar position="static">
                    <Toolbar>
                        <RestTimer />
                        <ThemeProvider theme={getTitleTheme()}>
                            <Typography variant="h3" sx={{ flexGrow: 1 }}> {template.name} </Typography>
                        </ThemeProvider>
                        
                        <Box sx={{backgroundColor: 'white', color: 'black', borderRadius: '25%'}}>
                            <IconButton onClick={() => {setCancelWorkoutModalVisible(true)}}> <CloseIcon /></IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <br />
                <Stack spacing={3}>
                    {template.exercises.map((ex, idx) => {
                        const exercise = exercises.filter(exer => exer.id == ex.id)[0]; 
                        if (exercise == undefined) {
                            return <CircularProgress key={idx} />
                        }
                        return <WorkoutExercise exercise={exercise} numSets={ex.numSets} repGoal={ex.repGoal} key={idx} exerciseIdx={idx} workoutState={workoutState} workoutHistory={workouts}/>
                    })}
                    <Button onClick={() => {setCancelWorkoutModalVisible(true)}}>Cancel workout</Button>
                    {workoutState.saveWorkoutQueryEnabled ? <Container><LinearProgress /></Container> : <Button onClick={() => workoutState.setSaveWorkoutQueryEnabled(true)}>Finish workout</Button>}
                    <br/>
                </Stack>
            </div>
            <CancelWorkoutModal visible={cancelWorkoutModalVisible} setVisible={setCancelWorkoutModalVisible}/>
        </div>
    )

}