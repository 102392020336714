import React, { useEffect, useState } from 'react';
import { ExerciseSet } from '../../../../types/types';
import { useQuery } from 'react-query';
import { saveWorkout } from '../../../../api/api';

export const useWorkoutState = (template) => {

    const getInitialWorkoutState = () => {
        if (localStorage.getItem("currentWorkoutState") !== undefined && localStorage.getItem("currentWorkoutState") !== null) {
            return JSON.parse(localStorage.getItem("currentWorkoutState")); 
        }
        const listOfLists: ExerciseSet[][] = [];
        template.exercises.forEach(exercise => {
            const listOfSets = [];
            for (let i = 0; i < exercise.numSets; i++) {
                listOfSets.push({
                    weight: undefined,
                    reps: undefined,
                    isCompleted: false, 
                    exerciseId: exercise.id
                })
            }
            listOfLists.push(listOfSets);
        })
        return listOfLists;
    }

    const [state, setState] = useState<ExerciseSet[][]>(getInitialWorkoutState());
    useEffect(() => {
        localStorage.setItem("currentWorkoutState", JSON.stringify(state)); 
    }, [state])


    const changeSetStatus = (exerIdx, setIdx, newVal, isChangeWeight, isChangeReps, isChangeCompleted) => {
        const listOfLists: ExerciseSet[][] = [];
        state.forEach((exercise, exerciseIdx) => {
            if (exerciseIdx !== exerIdx) {
                listOfLists.push(exercise);
            } else {
                const listOfSets = [];
                for (let i = 0; i < exercise.length; i++) {
                    if (i !== setIdx) {
                        listOfSets.push(exercise[i]); 
                    } else {
                        listOfSets.push({
                            exerciseId: exercise[i].exerciseId, 
                            isCompleted: isChangeCompleted ? newVal : exercise[i].isCompleted, 
                            weight: isChangeWeight ? newVal : exercise[i].weight, 
                            reps: isChangeReps ? newVal : exercise[i].reps
                        })
                    }
                }
                listOfLists.push(listOfSets);
            }
        })
        setState(listOfLists);
    }

    const changeReps = (exerIdx, setIdx, newVal) => {
        changeSetStatus(exerIdx, setIdx, newVal, false, true, false)
    }

    const changeWeight = (exerIdx, setIdx, newVal) => {
        changeSetStatus(exerIdx, setIdx, newVal, true, false, false)
    }

    const changeCompleted = (exerIdx, setIdx, newVal) => {
        changeSetStatus(exerIdx, setIdx, newVal, false, false, true)
    }

    const [saveWorkoutQueryEnabled, setSaveWorkoutQueryEnabled] = useState(false); 
    useQuery(["saveWorkoutQuery", {
        createdBy: localStorage.getItem("userName"), 
        templateId: template.id, 
        exercises: state, 
        id: "temp", 
        timestamp: "123"
    }], saveWorkout, {
        enabled: saveWorkoutQueryEnabled, 
        onSuccess: response => {
            setSaveWorkoutQueryEnabled(false); 
            localStorage.removeItem("currentWorkoutExerciseList"); 
            localStorage.removeItem("currentWorkoutTemplate"); 
            localStorage.removeItem("currentWorkoutState"); 
            window.location.replace('/home'); 
        }, 
        onError: err => {
            console.log(err); 
            setSaveWorkoutQueryEnabled(false); 
        }, 
        retry: 3
    })

    return {
        state, 
        changeReps,
        changeWeight, 
        changeCompleted, 
        saveWorkoutQueryEnabled, 
        setSaveWorkoutQueryEnabled
    }
}