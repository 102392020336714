import React, { useEffect, useState } from 'react';
import GoosefitHeader from '../../other/GoosefitHeader';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Container, Stack, Card, LinearProgress, IconButton, TextField, Paper, Button, Alert, AlertTitle, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { staticTemplates } from '../../../resources/staticData';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TemplatePreviewModal from './subcomponents/TemplatePreviewModal';
import AddIcon from '@mui/icons-material/Add';
import VideoModal from '../../other/VideoModal';
import WorkoutViewModal from './subcomponents/WorkoutViewModal';
import AddFriendModal from './subcomponents/AddFriendModal';
import PersonIcon from '@mui/icons-material/Person';
import { useExercisesContext } from '../../../contexts/ExercisesContext';
import { useTemplatesContext } from '../../../contexts/TemplatesContext';
import { useWorkoutContext } from '../../../contexts/WorkoutContext';
import { useFriendsContext } from '../../../contexts/FriendsContext';
import { useFriendRequestsContext } from '../../../contexts/FriendRequestsContext';

export default function HomeScreen({ }) {

    const {
        templates,
        templatesIsLoading
    } = useTemplatesContext();

    const {
        exercises,
        exercisesIsLoading
    } = useExercisesContext();

    const {
        workouts,
        workoutsIsLoading
    } = useWorkoutContext();

    const {
        friends, 
        friendsIsLoading, 
        reloadFriends
    } = useFriendsContext(); 

    const {
        requests, 
        requestsIsLoading, 
        respondToRequest, 
        responseEnabled, 
        responseIdx
    } = useFriendRequestsContext(); 


    const [templatePreviewModalVisible, setTemplatePreviewModalVisible] = useState(false);
    const [modalTemplate, setModalTemplate] = useState(staticTemplates[0]);

    const [exerciseModalVisible, setExerciseModalVisible] = useState(false);
    const [modalExercise, setModalExercise] = useState({ id: 'temp' });

    const [workoutModalVisible, setWorkoutModalVisible] = useState(false);
    const [modalWorkout, setModalWorkout] = useState({ templateId: 'temp', exercises: [] })

    const [addFriendModalVisible, setAddFriendModalVisible] = useState(false); 

    const [exerciseSearchTerm, setExerciseSearchTerm] = useState('');
    const [filteredExercises, setFilteredExercises] = useState([]);

    useEffect(() => {
        setFilteredExercises(exercises);
    }, [exercises])
    useEffect(() => {
        setFilteredExercises(exercises.filter(exer => JSON.stringify(exer).toLowerCase().includes(exerciseSearchTerm.toLowerCase())));
    }, [exerciseSearchTerm])

    const hasActiveWorkout = (): boolean => {
        const storedExercises = localStorage.getItem("currentWorkoutExerciseList");
        const storedTemplate = localStorage.getItem("currentWorkoutTemplate");
        const storedState = localStorage.getItem("currentWorkoutState");

        return (storedExercises !== undefined) && (storedExercises !== null) &&
            (storedTemplate !== undefined) && (storedTemplate !== null) &&
            (storedState !== undefined) && (storedState !== null);
    }

    const discardActiveWorkout = () => {
        localStorage.removeItem("currentWorkoutExerciseList");
        localStorage.removeItem("currentWorkoutTemplate");
        localStorage.removeItem("currentWorkoutState");
        window.location.reload();
    }


    return (
        <div style={{ width: "100%" }}>
            <GoosefitHeader />
            <br />
            <Stack spacing={2}>
                {(!requestsIsLoading && requests.length > 0) && 
                    <Stack>
                        {requests.map((request, idx) => {
                            return (
                                <Alert 
                                    key={idx}
                                    severity='info' 
                                    icon={<PersonIcon />}
                                    action={!(responseEnabled && responseIdx == idx) ? <div>
                                        <Button size='small' sx={{color: 'green'}} onClick={() => respondToRequest(idx, true)}>Accept</Button>
                                        <Button size='small' sx={{color: 'red'}}  onClick={() => respondToRequest(idx, false)}>Deny</Button>
                                    </div> : <CircularProgress />}
                                >
                                    <AlertTitle>Friend Request Received</AlertTitle>
                                    {`${request.from}`}
                                </Alert>
                            )
                        })}
                    </Stack>
                }
                {hasActiveWorkout() && <Paper elevation={3}>
                    <Stack>
                        <br />
                        <Typography variant='h6'>Continue your active workout?</Typography>
                        <Button onClick={() => window.location.replace('/workout')}>Yes, Continue my workout</Button>
                        <Button onClick={discardActiveWorkout}>No, discard my workout</Button>

                    </Stack>
                </Paper>}
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Stack direction="row">
                            <Typography variant='h4'>Templates</Typography>
                            <IconButton
                                onClick={() => {
                                    window.location.replace('/createTemplate');
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Stack>

                    </AccordionSummary>
                    <AccordionDetails>
                        {!templatesIsLoading ? <Grid container spacing={2}>
                            {templates.map((template, idx) => {
                                return (
                                    <Grid xs={6} key={idx}>
                                        <Card variant='outlined' onClick={() => { setTemplatePreviewModalVisible(true); setModalTemplate(templates[idx]) }}>
                                            <br />
                                            <div>{template.name}</div>
                                            <br />
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid> : <LinearProgress />}
                        {(!templatesIsLoading && templates.length == 0) &&
                            <Card variant='outlined'>
                                <br />
                                <div>No Templates Yet</div>
                                <br />
                            </Card>
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Stack direction="row">
                            <Typography variant='h4'>Exercises</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={1}>
                            <TextField
                                value={exerciseSearchTerm}
                                onChange={(event) => setExerciseSearchTerm(event.target.value)}
                                placeholder='Search'
                                size='small'
                            />
                            <br />
                            {!exercisesIsLoading ? <Grid container spacing={1}>
                                {filteredExercises.map((exercise, idx) => {
                                    return (
                                        <Grid xs={6} key={idx}>
                                            <Card variant='outlined' sx={{height: '5em'}} onClick={() => { setModalExercise(exercise); setExerciseModalVisible(true) }}>
                                                <Typography sx={{marginTop: '1em', marginLeft: '0.5em', marginRight: '0.5em'}}>{exercise.name}</Typography>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                            </Grid> : <LinearProgress />}

                        </Stack>

                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Stack direction="row">
                            <Typography variant='h4'>Past Workouts</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={1}>
                            {!workoutsIsLoading ? <Grid container spacing={2}>
                                {workouts.map((workout, idx) => {
                                    const thisTemplate = templates.filter(template => template.id === workout.templateId)[0];

                                    const date = new Date(parseInt(workout.timestamp));
                                    return (
                                        <Grid xs={6} key={idx}>
                                            <Card variant='outlined' onClick={() => { setModalWorkout(workout); setWorkoutModalVisible(true) }}>
                                                <Stack sx={{ margin: '0.5em' }} spacing={2}>
                                                    {thisTemplate && <Typography>{thisTemplate.name}</Typography>}
                                                    {thisTemplate && <Typography>{new Date(parseInt(workout.timestamp)).toLocaleDateString()}</Typography>}
                                                </Stack>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                            </Grid> : <LinearProgress />}
                            {(!workoutsIsLoading && workouts.length == 0) &&
                            <Card variant='outlined'>
                                <br />
                                <div>No Finished Workouts Yet</div>
                                <br />
                            </Card>
                        }
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Stack direction="row">
                            <Typography variant='h4'>Friends</Typography>
                            <IconButton
                                onClick={() => {setAddFriendModalVisible(true)}}
                            >
                                <AddIcon />
                            </IconButton>
                        </Stack> 

                    </AccordionSummary>
                    <AccordionDetails>
                        {!friendsIsLoading ? <Grid container spacing={2}>
                            {friends.map((friend, idx) => {
                                return (
                                    <Grid xs={12} key={idx}>
                                        <Card variant='outlined' onClick={() => {}}>
                                            <Stack direction='row'>
                                                <img style={{borderRadius: '15%'}} src={friend.picture} height='60px' alt={friend.name}></img>
                                                <Typography sx={{margin: '1em'}}>{friend.name}</Typography>
                                            </Stack>  
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid> : <LinearProgress />}
                        {(!friendsIsLoading && friends.length == 0) &&
                            <Card variant='outlined'>
                                <br />
                                <div>No Friends Yet</div>
                                <br />
                            </Card>
                        }
                    </AccordionDetails>
                </Accordion>
                
            </Stack>

            {(!templatesIsLoading && !exercisesIsLoading) && <TemplatePreviewModal visible={templatePreviewModalVisible} setVisible={setTemplatePreviewModalVisible} template={modalTemplate} exercises={exercises} />}

            <VideoModal visible={exerciseModalVisible} setVisible={setExerciseModalVisible} exercise={modalExercise} workoutHistory={workouts} />
            <WorkoutViewModal visible={workoutModalVisible} setVisible={setWorkoutModalVisible} exercises={exercises} workout={modalWorkout} templates={templates} />
            <AddFriendModal visible={addFriendModalVisible} setVisible={setAddFriendModalVisible} />
        </div>
    )
}