import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getModalStyle } from '../../../../utils/utils';

export default function CancelWorkoutModal({ visible, setVisible }) {

    const onClose = () => {
        setVisible(false); 
    }

    const onConfirmCancelWorkout = () => {
        localStorage.removeItem("currentWorkoutExerciseList"); 
        localStorage.removeItem("currentWorkoutTemplate"); 
        localStorage.removeItem("currentWorkoutState"); 
        window.location.replace('/home'); 
    }

    return (
        <Modal
            open={visible}
            onClose={onClose}
        >
            <Box sx={getModalStyle()}>
                <Stack spacing={2}>
                    <Typography>Are you sure you want to cancel this workout?</Typography>
                    <Button variant='outlined' onClick={onClose} color='error'>Nevermind, continue</Button>
                    <Button variant='outlined' onClick={onConfirmCancelWorkout}>Cancel Workout</Button>
                </Stack>
            </Box>


        </Modal>
    )
}