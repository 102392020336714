import React, {useState, useContext, createContext} from 'react';
import { useQuery } from 'react-query';
import { listExercises, listFriends } from '../api/api';
import { User } from '../types/types';

const FriendsContext = createContext(null); 

export function useFriendsContext() {
    return useContext(FriendsContext); 
}

export default function FriendsContextProvider(props) {

    const [friends, setFriends] = useState<User[]>([]); 
    const [friendsIsLoading, setFriendsIsLoading] = useState(true); 

    useQuery(["listFriends", localStorage.getItem("userName")], listFriends, {
        enabled: friendsIsLoading, 
        onSuccess: response => {
            setFriendsIsLoading(false); 
            setFriends(response.data.friends)
        }, 
        onError: err => {
            console.log(err); 
            setFriendsIsLoading(false); 
        }, 
        retry: 3 
    })

    const reloadFriends = () => {
        setFriendsIsLoading(true); 
    }

    const returnValue = {
        friends, 
        friendsIsLoading, 
        reloadFriends
    }

    return (
        <>
            <FriendsContext.Provider value={returnValue}>
                {props.children}
            </FriendsContext.Provider>
        </>
    )
}