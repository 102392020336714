import { Box, Card, Container, Modal, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getModalStyle } from '../../../utils/utils';

export default function VideoModal({ visible, setVisible, exercise, workoutHistory }) {

    const [tabIdx, setTabIdx] = useState(0);
    const onTabChange = (event, newValue) => {
        setTabIdx(newValue);
    }

    const [exerciseHistory, setExerciseHistory] = useState([]);
    const getExerciseHistory = () => {
        const history = [];
        workoutHistory.forEach(workout => {
            const setsOfThisExerciseInThisWorkout = [];
            workout.exercises.forEach(workoutExercise => {
                workoutExercise.forEach(set => {
                    if (set.exerciseId == exercise.id && set.isCompleted) {
                        setsOfThisExerciseInThisWorkout.push(set);
                    }
                })
            })
            if (setsOfThisExerciseInThisWorkout.length > 0) {
                history.push({
                    workoutId: workout.id,
                    templateId: workout.templateId,
                    timestamp: workout.timestamp,
                    sets: setsOfThisExerciseInThisWorkout,
                })
            }
        })
        return history;
    }

    useEffect(() => {
        setExerciseHistory(getExerciseHistory());
    }, [exercise, workoutHistory])

    return (
        <Modal
            open={visible}
            onClose={() => setVisible(false)}
        >
            <Box sx={getModalStyle()}>
                <Stack>
                    <Typography variant='h6'>{exercise.name}</Typography>
                    <Tabs variant='fullWidth' value={tabIdx} onChange={onTabChange}>
                        <Tab label="History" />
                        <Tab label="Video" />
                    </Tabs>
                    <br/>
                    {tabIdx == 0 &&
                        <Container>
                            <Stack spacing={1}>
                                {exerciseHistory.length > 0 ? exerciseHistory.map(instanceOfExercise => {
                                    return (
                                    <Card sx={{padding: '0.5em'}} variant='outlined'>
                                        <Typography>{new Date(parseInt(instanceOfExercise.timestamp)).toLocaleDateString()}</Typography>
                                        {instanceOfExercise.sets.map((set, idx) => {
                                            return <Typography>Set {idx + 1} --- {set.weight} lbs x {set.reps} reps</Typography>
                                        })}
                                    </Card>
                                    )
                                }) : <Card sx={{padding: '0.5em'}}><Typography>No History Yet</Typography></Card>}
                            </Stack>
                        </Container>

                    }

                    {
                        tabIdx == 1 &&
                        <iframe width="100%"
                            src={"https://www.youtube.com/embed/" + exercise.videoId}>
                        </iframe>
                    }
                </Stack>

            </Box>
        </Modal>
    )
}