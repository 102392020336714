import React, {useState, useContext, createContext} from 'react';
import { useQuery } from 'react-query';
import { listTemplates } from '../api/api';
import { WorkoutTemplate } from '../types/types';

const TemplatesContext = createContext(null); 

export function useTemplatesContext() {
    return useContext(TemplatesContext); 
}

export default function TemplatesContextProvider(props) {

    const [templates, setTemplates] = useState<WorkoutTemplate[]>([]); 
    const [templatesIsLoading, setTemplatesIsLoading] = useState(true); 

    useQuery(["listTemplates", localStorage.getItem("userName")], listTemplates, {
        enabled: templatesIsLoading, 
        onSuccess: response => {
            setTemplatesIsLoading(false); 
            setTemplates(response.data.templates)
        }, 
        onError: err => {
            console.log(err); 
            setTemplatesIsLoading(false); 
        }, 
        retry: 3 
    })

    const returnValue = {
        templates, 
        templatesIsLoading
    }

    return (
        <>
            <TemplatesContext.Provider value={returnValue}>
                {props.children}
            </TemplatesContext.Provider>
        </>
    )
}